import './Illustration.css';
import photostrip from './img/photostrip.svg';
import photostrip_japan from './img/photostrip_japan.svg';
import { useInView } from 'react-intersection-observer';
import { isEnglish } from '../App.js'
import React from "react"

function Illustration() {
    const { ref: firstDateRef, inView: firstDateInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });
    
    const { ref: photostripRef, inView: photoStripInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: photoStripJapanTextRef, inView: photoStripJapanTextInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: marriedDateRef, inView: marriedDateInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 0% of the component is visible
    });

    const { ref: closingTextRef, inView: closingTextInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 0% of the component is visible
    });

    return (
        <div className="WrapperIllustration">
            <div ref={firstDateRef} className={`firstDateTimeline ${firstDateInView ? 'is-visible' : ''}`}>
                <p>
                    04<br/>
                    08<br/>
                    18
                </p>

                <div className="borderVertical"></div>
            </div>

            <div className="photoIllustration">
                <img ref={photostripRef} src={photostrip} alt="Photostrip" className={`photostrip ${photoStripInView ? 'is-visible' : ''}`}/>
                <img ref={photoStripJapanTextRef} src={photostrip_japan} alt="Photostrip Japan" className={`photostripJapanText ${photoStripJapanTextInView ? 'is-visible' : ''}`}/>
            </div>

            <div ref={marriedDateRef} className={`marriedDateTimeline ${marriedDateInView ? 'is-visible' : ''}`}>
                <div className="borderVertical"></div>

                <p>
                    02<br/>
                    11<br/>
                    24
                </p>
            </div>

            <div ref={closingTextRef} className={`closingText ${closingTextInView ? 'is-visible' : ''}`}>
                <p>{ isEnglish ? "Join us to witness the union of our love." : "Gabung dan saksikan penyatuan cinta kami." }</p>
            </div>
        </div>
    )
}

export default Illustration;