import './Cover.css';
import mainCover from './img/main_cover.svg';
import symbolLogo from './img/cover_symbol.svg';
import coverDate from './img/cover_date.svg';
import coverFlower from '../globalImage/cover_flower.svg';
import CircleButton from '../components/CircleButton';
import { supabase, isEnglish, useMediaQuery } from '../App.js'
import React, { useState, useEffect } from "react";

function Cover({ openInvitationHandler, hideButtonOpenInv }) {
    const [fullName, setfullName] = useState("Family and Friends");
    const [idParam, setIdParam] = useState(null);
    const isPortrait = useMediaQuery('(orientation: portrait)');

    useEffect(() => {
        // Get the full pathname
        const urlParam = window.location.pathname;
        // Split the pathname into segments
        const segments = urlParam.split('/');
        // Get the last segment
        const lastSegment = segments[segments.length - 1];
        // Handle the case where the pathname ends with a trailing slash
        const idParam = lastSegment === '' ? segments[segments.length - 2] : lastSegment;

        setIdParam(idParam);
        getFullName(idParam);
    }, []);

    async function getFullName(idParam) {
        const networkUserData = await supabase
            .from("guests")
            .select("fullname")
            .eq("guestId", idParam)
            .single();

        if (networkUserData.data != null) {
            setfullName(networkUserData.data.fullname)
        }
    }

    return (
        <div className="WrapperCover">
            <div className="topCover">
                <div className="coverLeft">
                    <img src={symbolLogo} alt="Symbol Logo Image" className="symbolLogo"/>
                </div>
                <div className="coverMid">
                        <img src={mainCover} alt="Main Cover Image" className="mainCover"/>
                        <img src={coverFlower} alt="Cover Flower" className="coverFlower"/>
                </div>
                <div className="coverRight" identifier="spacer"/>
            </div>
            
            <div className="dateRow">
                <div className="coverDateWrapper">
                    <img src={coverDate} alt="Cover Date Image" className="coverDate"/>
                </div>
            </div>
            
            <div className="title">
                <div className="introText">
                    THE WEDDING OF
                </div>
                <div className="brideGroomNameText">
                    EDHO & ARETA
                </div>
            </div>

            <div className="guestMessage">
                <div className="dearText">
                    {isEnglish ? 'Dear' : 'Kepada'}&nbsp; 
                    <div className="guestNameText">
                        {fullName}
                    </div>
                    ,
                </div>

                <div className="invitationText">
                    {isEnglish ? <p>We invite you to join us to celebrate the most {isPortrait && (<br/>)}special day in our lives.</p> : <p>Kami mengundang Saudara/i untuk turut {isPortrait && (<br/>)}merayakan hari bahagia kami.</p>}
                </div>
            </div>

            <div className={`openInvitationButton ${hideButtonOpenInv ? 'hidden' : 'shown'}`}>
                <div className="buttonWrapper">
                    {/* 
                        Creating another wrapper because button already have Ripple wrapper, hence obstructing the keyframes
                        Instead the div wrapper that were applied the keyframes 
                    */}
                    <div className="animationWrapper">
                        <CircleButton 
                            text={ isEnglish ? "Open Invitation" : "Buka Undangan" } 
                            clickHandler={ openInvitationHandler }
                            horizontalPadding={ isPortrait ? "3%" : "1%" }
                            verticalPadding={ isPortrait ? "3%" : "1%" }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cover;