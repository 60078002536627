import './Masonry.css'
import React from 'react';

const PinterestGrid = ({ imageUrls }) => {
    return (
        <div className="pinterest-grid">
        {imageUrls.map((url, index) => (
            <div className="pinterest-grid-item" key={index}>
            <img src={url} alt={`Pinterest Image ${index}`} />
            </div>
        ))}
        </div>
    );
};

export default PinterestGrid;