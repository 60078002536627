import './GroomBride.css';
import edho from "./img/edho.png"
import areta from "./img/areta.png"
import edoJapan from "./img/edo_japan.png"
import aretaJapan from "./img/areta_japan.png"
import coverFlower from '../globalImage/cover_flower.svg';
import { useInView } from 'react-intersection-observer';
import { isEnglish, useMediaQuery } from '../App.js'
import React from "react";

function GroomBride() {
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const { ref: groomRef, inView: groomPhotoInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: groomNameRef, inView: groomNameInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 0% of the component is visible
    });

    const { ref: dividerRef, inView: dividerInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 0% of the component is visible
    });

    const { ref: brideNameRef, inView: BrideNameInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.5, // Trigger when 0% of the component is visible
    });

    const { ref: brideRef, inView: brideRefInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: closingRef, inView: closingInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });
    
    return (
        <div className="WrapperGroomBride">
            <div className="GroomSection">
                <div ref={groomRef} className={`GroomImageSection ${groomPhotoInView ? 'is-visible' : ''}`}>
                    <img src={edho} alt="Groom Image" className="groomImage"/>
                    <img src={edoJapan} alt="Groom Name Japan" className="groomNameJapan"/>
                    <img src={coverFlower} alt="Overlay Flower" className="groomFlowerOverlay"/>
                </div>

                <div ref={groomNameRef} className={`NameDescription ${groomNameInView ? 'is-visible' : ''}`}>
                    <p className="title">THE GROOM</p>
                    <p className="name">EDHO PRASETYO</p>
                    <p className="parents">
                        {
                            isEnglish ?
                                <p>
                                    The first son of<br/>
                                    Mr. Ferry Anggoro (†) & Mrs. Yanti Andriana
                                </p>
                            :
                                <p>
                                    Putra pertama dari<br/>
                                    Tn. Ferry Anggoro (†) & Ny. Yanti Andriana
                                </p>
                        }
                    </p>
                </div>

                { isPortrait && (
                    <div ref={dividerRef} className={`divider ${dividerInView ? 'is-visible' : ''}`}>
                        <div className="borderLeft"></div>
                        <p>{isEnglish ? "together with" : "bersama dengan"}</p>
                        <div className="borderRight"></div>
                    </div>
                )}
            </div>

            {
                isPortrait ?
                    <div className="BrideSection">
                        <div ref={brideRef} className={`BrideImageSection ${brideRefInView ? 'is-visible' : ''}`}>
                            <img src={areta} alt="Bride Image" className="brideImage"/>
                            <img src={aretaJapan} alt="Bride Name Japan" className="brideNameJapan"/>
                            <img src={coverFlower} alt="Overlay Flower" className="brideFlowerOverlay"/>
                        </div>

                        <div ref={brideNameRef} className={`NameDescription ${BrideNameInView ? 'is-visible' : ''}`}>
                            <p className="title">THE BRIDE</p>
                            <p className="name">
                                ARETA<br/>
                                SELENA KHRISTA
                            </p>
                            <p className="parents">
                                {
                                    isEnglish ?
                                        <p>
                                            The first daughter of<br/>
                                            Mr. Ioanes Rakhmat & Mrs. Enny Riani Mardjuadi
                                        </p>
                                    :
                                        <p>
                                            Putri pertama dari<br/>
                                            Tn. Ioanes Rakhmat & Ny. Enny Riani Mardjuadi
                                        </p>
                                }
                            </p>
                        </div>
                    </div>
                :
                    <div className="BrideSection">
                        <div ref={brideNameRef} className={`NameDescription ${BrideNameInView ? 'is-visible' : ''}`}>
                            <p className="title">THE BRIDE</p>
                            <p className="name">ARETA SELENA</p>
                            <p className="parents">
                                {
                                    isEnglish ?
                                        <p>
                                            The first daughter of<br/>
                                            Mr. Ioanes Rakhmat & Mrs. Enny Riani Mardjuadi
                                        </p>
                                    :
                                        <p>
                                            Putri pertama dari<br/>
                                            Tn. Ioanes Rakhmat & Ny. Enny Riani Mardjuadi
                                        </p>
                                }
                            </p>
                        </div>

                        <div ref={brideRef} className={`BrideImageSection ${brideRefInView ? 'is-visible' : ''}`}>
                            <img src={areta} alt="Bride Image" className="brideImage"/>
                            <img src={aretaJapan} alt="Bride Name Japan" className="brideNameJapan"/>
                            <img src={coverFlower} alt="Overlay Flower" className="brideFlowerOverlay"/>
                        </div>
                    </div>
            }

            <div ref={closingRef} className={`BrideGroomClosing ${closingInView ? 'is-visible' : ''}`}>
                <p>{ isEnglish ? "Soon to be united as one!" : "Sebentar lagi akan bersatu!" }</p>
            </div>
            
        </div>
    )
}

export default GroomBride;