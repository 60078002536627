import React, { useState } from 'react';
import play from '../globalImage/play.svg'
import pause from '../globalImage/pause.svg'
import './AudioPlayer.css'
import audioFile from '../files/RADWIMPS - Sparkle.mp3'; // Adjust the path as necessary

function AudioPlayer({ audioRef, handlePlay, handleEnded, parentImageState }) {
  return (
    <div className="audio-player">
      <audio ref={audioRef} src={audioFile} loop onEnded={handleEnded}></audio>

      <div className="controls">
        <img src={ parentImageState } className="playPauseFloating" onClick={ handlePlay }/>
      </div>
    </div>
  );
};

export default AudioPlayer;