import './TextField.css'
import React from "react";
import PropTypes from 'prop-types';

const TextField = ({ label, value, placeholder, disabled }) => {
  return (
    <div className="text-field">
      <label>{label}</label>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        disabled= {disabled}
      />
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TextField;