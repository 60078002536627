import './Comments.css';
import coverFlower from '../globalImage/cover_flower.svg';
import { useInView } from 'react-intersection-observer';
import React from "react"
import { isEnglish } from '../App';

function Comments({ commentLists }) {
    const { ref: commentListsRef, inView: commentListsInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.2, // Trigger when 0% of the component is visible
    });

    return (
        <div ref={commentListsRef} className={`WrapperComments ${commentListsInView ? 'is-visible' : ''}`}>
            <img src={coverFlower} alt="Flower Divider" className="flowerDivider"/>

            <div className="CommentBoard">
                <div className="CommentBoardHeader">
                    <p className="CommentBoardTitle">WELL WISHES</p>
                    <p className="CommentBoardSubtitle">for エド＆アレタ</p>
                </div>
                { commentLists.length == 0 ?
                    <div className='EmptyWishesSection'>
                        <div className='EmptyWishesTextWrapper'>
                            { isEnglish ?
                                <p className='EmptyWishesText'>Share your heartfelt wishes<br/>for Edho and Areta!</p>
                            :
                                <p className='EmptyWishesText'>Bagikan harapan terbaikmu<br/>untuk Edho dan Areta!</p>
                            }
                        </div>
                    </div>
                :
                    <ul>
                        {commentLists.map(item => (
                            <li key={item.id}>
                                <div className="CommentCard">
                                    <div className="CommentName">
                                        {item.fullname}
                                    </div>
                                    <div className="CommentText">
                                        {item.wishes}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
      );
}

export default Comments;