import './Address.css';
import { useInView } from 'react-intersection-observer';
import CircleButton from '../components/CircleButton';
import { isEnglish, useMediaQuery } from '../App.js'
import React from "react"

function Address({ isHolyMatrimonyOnly }) {
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const { ref: locationDetailsRef, inView: locationDetailsInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: dressCodeRef, inView: dressCodeInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const { ref: colorPalleteRef, inView: colorPalleteInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.3, // Trigger when 0% of the component is visible
    });

    const holyMatrimonyDirectionHandler = e => {
        window.open("https://maps.app.goo.gl/EE8AZME2buMonYuF6", '_blank');
    };

    const receptionDirectionHandler = e => {
        window.open("https://maps.app.goo.gl/zMig4497FVYvtWRA9", '_blank');
    };

    return (
        <div className="WrapperAddress">
            <div ref={locationDetailsRef} className={`LocationDetails ${locationDetailsInView ? 'is-visible' : ''}`}>
                <p className="Date">
                    { isEnglish ? <p>SATURDAY,<br/></p> : <p>SABTU,<br/></p> }
                    2 NOVEMBER 2024
                </p>

                <div className="HolyMatrimony">
                    <p className="HolyMatrimonyText">
                        { isEnglish ? "HOLY MATRIMONY" : "IBADAH PEMBERKATAN" }
                    </p>

                    <p className="HolyMatrimonyDateTime">
                        10:00<br/>
                        GKI Gading Indah
                    </p>

                    <p className="HolyMatrimonyDistrict">
                        Kelapa Gading, Jakarta Utara
                    </p>

                    <CircleButton 
                        text={ isEnglish ? "See Direction" : "Tunjukkan Arah" } 
                        clickHandler={ holyMatrimonyDirectionHandler }
                        horizontalPadding={ "12px" }
                        verticalPadding={ "8px" }
                    />
                </div>

                { !isHolyMatrimonyOnly && (
                    <div className="WeddingReception">
                        <p className="WeddingReceptionText">
                            { isEnglish ? "WEDDING RECEPTION" : "RESEPSI PERNIKAHAN" }
                        </p>

                        <p className="WeddingReceptionDateTime">
                            18:30<br/>
                            <p className="HotelName">
                                { isEnglish ? "Mercure Gatot Subroto, 7th Floor" : "Mercure Gatot Subroto, Lantai 7" }
                            </p>
                        </p>

                        <p className="WeddingReceptionDistrict">
                            Kuningan Barat, Jakarta Selatan
                        </p>

                        <CircleButton 
                            text={ isEnglish ? "See Direction" : "Tunjukkan Arah" } 
                            clickHandler={ receptionDirectionHandler }
                            horizontalPadding={ "12px" }
                            verticalPadding={ "8px" }
                        />
                    </div>
                )}
            </div>

            <div className="dressCode">
                <p ref={dressCodeRef}  className={`dressCodeSuggestion ${dressCodeInView ? 'is-visible' : ''}`}>
                    {
                        isEnglish ?
                        <p>
                            It would mean a lot to us if you could wear { isPortrait && ( <br/> )}
                            these color tones on our special day.
                        </p>
                        :
                        <p>
                            Akan sangat berarti jika Saudara/i dapat hadir { isPortrait && ( <br/> )}
                            dengan mengenakan warna serupa ini.
                        </p>
                    }
                </p>

                <div ref={colorPalleteRef} className="colorPallete">
                    <div className={`cream ${colorPalleteInView ? 'start-animate' : ''}`}></div>
                    <div className={`lightBrown ${colorPalleteInView ? 'start-animate' : ''}`}></div>
                    <div className={`gray ${colorPalleteInView ? 'start-animate' : ''}`}></div>
                    <div className={`black ${colorPalleteInView ? 'start-animate' : ''}`}></div>
                    <div className={`white ${colorPalleteInView ? 'start-animate' : ''}`}></div>
                </div>
            </div>
        </div>
    )
}

export default Address;