import './Gift.css'
import Clipboard from './img/clipboard.svg'
import Check from '../globalImage/check.svg'
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { isEnglish, useMediaQuery } from '../App';
import { useInView } from 'react-intersection-observer';

function Gift() {
    const { ref: giftRef, inView: giftInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0, // Trigg
    })
    const isPortrait = useMediaQuery('(orientation: portrait)');

    const [areCopied, setAreCopied] = useState(Clipboard)
    const [edCopied, setEdCopied] = useState(Clipboard)

    const handleCopyToClipboardAreta = () => {
        copy('6300843105');
        setAreCopied(Check);
        setTimeout(() => {
            setAreCopied(Clipboard);
        }, 1000)
    };

    const handleCopyToClipboarEdho = () => {
        copy('2940499855');
        setEdCopied(Check);
        setTimeout(() => {
            setEdCopied(Clipboard);
        }, 1000)
    };
      
    return (
        <div ref={giftRef} className={`WrapperGift ${giftInView ? 'is-visible' : ''}`}>
            <div className="GiftHeader">
                <p className="GiftHeaderTitle">WEDDING GIFT</p>
                <p className="GiftHeaderSubtitle">結婚祝い</p>
            </div>

            <p className="GiftDescription">
                {
                    isEnglish ?
                    <p>
                        For our dear family & friends who wish to send us a gift, {isPortrait && (<br/>)}
                        we would be truly grateful for your kindness.
                    </p>
                    :
                    <p>
                        Bagi keluarga & sahabat yang ingin mengirimkan hadiah, {isPortrait && (<br/>)}
                        kami akan sangat berterima kasih atas kebaikan Saudara/i.
                    </p>
                }
                
            </p>

            <div className="BankAccount">
                <div className="OneAccount">
                    <div className="NumberBank">
                        <p className="AccountNumber">6300843105</p>
                        <img src={areCopied} alt="Copy to Clipboard" className="copyClipboard" onClick={ handleCopyToClipboardAreta }/>
                    </div>
                    <p>BCA - Areta Selena Khrista</p>
                </div>
                <div className="DividerAccount"/>
                <div className="OneAccount">
                    <div className="NumberBank">
                        <p className="AccountNumber">2940499855</p>
                        <img src={edCopied} alt="Copy to Clipboard" className="copyClipboard" onClick={ handleCopyToClipboarEdho }/>
                    </div>
                    <p>BCA - Edho Prasetyo</p>
                </div>
            </div>
        </div>
    )
}

export default Gift;