import './Reservation.css'
import TextField from '../components/TextField';
import TextArea from '../components/TextArea';
import Dropdown from '../components/Dropdown';
import RadioButtons from '../components/RadioButtonLeftRight.js';
import CircleButton from '../components/CircleButton';
import React, { useState, useEffect } from "react"
import { useInView } from 'react-intersection-observer';
import { supabase, isEnglish, useMediaQuery } from '../App.js'

const createArray = (num) => {
    return Array.from({ length: num }, (_, i) => i + 1);
};

function Reservation({ handleSubmitReservation, userData, idParam }) {
    const { ref: rsvpRef, inView: rsvpInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.1 // Trigger when 0% of the component is visible
    });
    const isPortrait = useMediaQuery('(orientation: portrait)');

    const HMR = { title: isEnglish ? "Holy Matrimony and Reception" : "Pemberkatan dan Resepsi" }
    const R = { title: isEnglish ? "Reception only" : "Hanya Resepsi" }
    const HM = { title: isEnglish ? "Holy Matrimony only" : "Hanya Pemberkatan" }
    const NA = { title: isEnglish ? "Can't come" : "Tidak bisa datang" }

    const dropDownVariantA = [,
        HMR.title,
        HM.title,
        R.title,
        NA.title
    ]

    const dropDownVariantB = [
        HM.title,
        NA.title
    ]

    const [fullName, setfullName] = useState(userData.fullname);
    const [selectedDropDown, setselectedDropDown] = useState("");
    const [selectedNumberGuests, setSelectedNumberGuests] = useState(userData.is_single ? userData.number_of_guests : "");
    const [dropdownOptions, setDropdownOption] = useState(userData.type == 1 ? dropDownVariantA : dropDownVariantB);
    const [guestDropdownOptions, setGuestDropdownOptions] = useState(!userData.is_single && createArray(userData.number_of_guests));
    const [enableGuestDropdown, setEnableGuestDropdown] = useState(!userData.is_single);
    const [wishes, setWishes] = useState("");

    const handleWishesChange = (e) => {
        setWishes(e.target.value)
    }
  
    const handleDropdownSelect = (option) => {
        setselectedDropDown(option);
    };

    const handleGuestsDropdownSelect = (option) => {
        setSelectedNumberGuests(option);
    };

    const onClickSubmitButton = () => {
        var dropDownAbbr = ""
        if (selectedDropDown == HMR.title) {
            dropDownAbbr = "HMR"
        }else if (selectedDropDown == HM.title) {
            dropDownAbbr = "HM"
        }else if (selectedDropDown == R.title) {
            dropDownAbbr = "R"
        }else if (selectedDropDown == NA.title) {
            dropDownAbbr = "NA"
        }

        const timestamp = new Date().toISOString(); // Get current timestamp in ISO 8601 format

        if (wishes != "") {
            const updateWithWishes = {
                attendance_confirmation: dropDownAbbr,
                number_of_guests: selectedNumberGuests,
                wishes: wishes,
                modifiedAt: timestamp
            }
    
            handleSubmitReservation(updateWithWishes)
        } else {
            const updateWithoutWishes = {
                attendance_confirmation: dropDownAbbr,
                number_of_guests: selectedNumberGuests,
                modifiedAt: timestamp
            }
    
            handleSubmitReservation(updateWithoutWishes)
        }

        // Reset everything
        setselectedDropDown("")
        if (!userData.is_single) {
            setSelectedNumberGuests("")
        }
        setWishes("")
    };

    return (
        <div ref={rsvpRef} className={`WrapperOuterReservation ${rsvpInView ? 'is-visible' : ''}`}>
            <div className="WrapperReservation">
                <div className="ReservationHeader">
                    <p className="Title">
                        RESERVATION
                    </p>

                    <p className="JapanTitle">
                        予約します
                    </p>

                    <p className="TitleDescription">
                        {
                            isEnglish ?
                            <p>
                                Please help us prepare better by letting us know {isPortrait && (<br/>)}
                                if you can join via the RSVP form below.
                            </p>
                            :
                            <p>
                                Bantu kami mempersiapkan dengan lebih baik {isPortrait && (<br/>)}
                                dengan mengisi form RSVP dibawah ini.
                            </p>
                        }
                    </p>

                    <div className="HeaderBorder"></div>
                </div>
                <div className="ReservationBody">
                    <TextField
                        label={isEnglish ? "Full Name" : "Nama Lengkap" }
                        value= { userData.fullname }
                        placeholder={ isEnglish ? "Type full name" : "Isi Nama Lengkap" }
                        disabled={ true }
                    />

                    <div className="textField">
                        <Dropdown
                            label={ isEnglish ? "RSVP Response" : "Konfirmasi Kehadiran" }
                            selectedOption= { selectedDropDown }
                            options={dropdownOptions} 
                            onSelect={handleDropdownSelect}
                            placeholderText={ isEnglish ? 'Choose your RSVP response' : 'Pilih konfirmasi kehadiran' }
                            isEnabled={ true }
                            zIndex={ 99 }
                        />
                    </div>

                    <div className="textField">
                        <Dropdown
                            label={ isEnglish ? "Number of Guests" : "Jumlah Tamu" }
                            selectedOption= { selectedNumberGuests }
                            options={guestDropdownOptions} 
                            onSelect={handleGuestsDropdownSelect}
                            placeholderText={ isEnglish ? 'Select number of guests' : 'Pilih jumlah tamu' }
                            isEnabled={ enableGuestDropdown }
                            zIndex={ 1 }
                        />
                    </div>

                    <div className="textField">
                        <TextArea
                            label={ isEnglish ? "Wishes" : "Ucapan" }
                            value= { wishes }
                            placeholder={ isEnglish ? "Write your wishes" : "Tuliskan ucapan" }
                            heightValue="91px"
                            handleChange={ handleWishesChange }
                        /> 
                    </div>
                </div>
                <div className="note">
                    {
                        isEnglish ?
                        <p>
                            Note: please submit once. If you submit again, {isPortrait && (<br/>)}
                            it will update your previous response.
                        </p>
                        :
                        <p>
                            Catatan: Mohon kirim hanya sekali. Jika dikirim ulang, 
                            jawaban sebelumnya akan diperbarui.
                        </p>
                    }
                </div>
                <div className="ButtonArea">
                    <CircleButton 
                        className="SubmitButton" 
                        text={ isEnglish ? "Submit" : "Kirim" } 
                        disabled= {
                            (fullName == "" || selectedDropDown == "" || selectedNumberGuests == "")
                        } 
                        clickHandler={ onClickSubmitButton }
                        horizontalPadding={ "36px" }
                        verticalPadding={ "12px" }
                    />
                </div>
            </div>
        </div>
    )
}

export default Reservation;