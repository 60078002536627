import './TextArea.css'
import React from "react";
import PropTypes from 'prop-types';

const TextArea = ({ label, value, placeholder, heightValue, handleChange }) => {
  const divStyle = {
    height: heightValue
  }

  return (
    <div className="text-area">
      <label>{label}</label>
      <textarea
        type="text"
        value={value}
        placeholder={placeholder}
        style={divStyle}
        onChange={handleChange}
      />
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default TextArea;