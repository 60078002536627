import './AddOnImage.css';
import topLandscape from './img/topLandscape.png';
import bottomLeft from './img/bottomLeft.png';
import bottomRight from './img/bottomRight.png';
import React from "react";

function AddOnImage() {
    return (
        <div className='WrapperAddOnImage'>
            <img src={ topLandscape } className="topLandscape"/>

            <div className='BottomPart'>
                <img src={ bottomLeft } className="image bottomLeft"/>
                <img src={ bottomRight } className="image bottomRight"/>
            </div>
        </div>
    )
}

export default AddOnImage;