import './Background.css';
import japanText from './img/japanText.svg';
import logo from './img/logo.gif';
import { useInView } from 'react-intersection-observer';
import React, { useState } from "react";
import { isEnglish, useMediaQuery } from '../App';

function Background() {
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const { ref: japanRef, inView: japanInView } = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 0% of the component is visible
    });

    return (
        <div ref={japanRef} className="WrapperBackground">
            <div className="UpperContent">
                <div className={`timeline ${japanInView ? 'is-visible' : ''}`}>
                    <img src={`${japanText}`} alt="Japan Text" className="japanText"/>
                    <div className="timeLineBorder"/>
                </div>
                <div className="titleDescription">
                    <img src={logo} alt="Logo" className="logo"/>
                    <div className="titleBackground">
                        <p>BUILDING A HOME,<br/>TOGETHER</p>
                    </div>
                    {
                        isPortrait ?
                            <div className="descriptionBackground">
                            { 
                                isEnglish ?
                                    <p>
                                    Our wedding logo beautifully<br/>
                                    integrates our initials, E and A,<br/>
                                    encircled to form a house.<br/>
            
                                    <br/>
                                    
                                    This symbolizes our union,<br/>
                                    where we each play a vital role:<br/>
                                    supporting one another,<br/>
                                    protecting one another.<br/>
            
                                    <br/>
            
                                    Together,<br/>
                                    we embody the essence of a home,<br/>
                                    united in a frame that represents<br/>
                                    our love and commitment as one.<br/>
                                    </p>
                                :
                                    <p>
                                    Logo pernikahan kami<br/>
                                    menggabungkan inisial E dan A,<br/>
                                    terlingkari, membentuk rumah.<br/>
                                    
                                    <br/>

                                    Melambangkan penyatuan kami,<br/>
                                    masing-masing berperan penting:<br/>
                                    saling mendukung dan melindungi.<br/>

                                    <br/>

                                    Bersama,<br/>
                                    kami wujudkan arti sebuah rumah,<br/>
                                    dalam bingkai cinta dan komitmen<br/>
                                    sebagai satu.<br/>
                                    </p>
                            }
                            </div>
                        :
                            <div className="descriptionBackground">
                            { 
                                isEnglish ?
                                    <p>
                                    Our wedding logo beautifully integrates our initials, E and A,<br/>
                                    encircled to form a house.<br/>
            
                                    <br/>
                                    
                                    This symbolizes our union, where we each play a vital role:<br/>
                                    supporting one another, protecting one another.<br/>
            
                                    <br/>
            
                                    Together, we embody the essence of a home,<br/>
                                    united in a frame that represents our love and commitment as one.<br/>
                                    </p>
                                :
                                    <p>
                                    Logo pernikahan kami menggabungkan inisial E dan A,<br/>
                                    terlingkari, membentuk rumah.<br/>
                                    
                                    <br/>

                                    Melambangkan penyatuan kami, masing-masing berperan penting:<br/>
                                    saling mendukung dan melindungi.<br/>

                                    <br/>

                                    Bersama, kami wujudkan arti sebuah rumah,<br/>
                                    dalam bingkai cinta dan komitmen sebagai satu.<br/>
                                    </p>
                            }
                            </div>
                    }
                    <div className="signBackground">
                        <p>#EdhoAreta</p>
                    </div>
                </div>
            </div>
            <div ref={japanRef} className={`BottomContent ${japanInView ? 'is-visible' : ''}`}>
                <p className="BottomContentSentences">
                    {
                        isEnglish ?
                            <p>“This is my commandment,<br/>
                            that you love one another as I have loved you.”<br/></p>
                        :
                            <p>“Inilah perintah-Ku, yaitu supaya kamu<br/>
                            saling mengasihi, seperti Aku telah mengasihi kamu.”</p>
                    }
                </p>

                <p className="BottomContentBible">
                    {   
                        isEnglish ?
                            <p>John 15:12</p>
                        :
                            <p>Yohanes 15:12</p>
                    }
                </p>
            </div>
        </div>
    )
}

export default Background;