import './Dropdown.css'
import chevronDown from '../globalImage/chevron-down.svg'
import checked from '../globalImage/checked.svg'

import React, { useState } from 'react';

const Dropdown = ({ label, selectedOption, options, onSelect, placeholderText, isEnabled, zIndex }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option); // Optional: You can pass the selected option back to parent component
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="dropdown">
      <label>{label}</label>
      <div className={`dropdown-toggle ${isOpen ? 'open' : ''} ${selectedOption ? 'filled' : ''} ${isEnabled ? 'enabled' : 'disabled'}`} onClick={ isEnabled ? toggleDropdown : null }>
        { selectedOption || placeholderText }
        { isEnabled && (
          <img src={chevronDown} className="ChevronDown" />
        )}
      </div>
      {isOpen && (
        <div className="dropdown-content">
          <ul className="dropdown-menu" style={{ zIndex: zIndex }}>
            {options.map((option) => (
              <li className="dropDownListItem" key={option} onClick={() => handleOptionClick(option)}>
                {option}
                { selectedOption == option && (
                  <img src={checked} className="Checked" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;