import './Closing.css'
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { isEnglish } from '../App.js'

function Closing() {
    const { ref: closingStatementRef, inView: closingStatementInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0, // Trigger when 0% of the component is visible
    });
    
    const { ref: creditsRef, inView: creditsInView } = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0, // Trigger when 0% of the component is visible
    });

    return (
        <div className="WrapperClosing">
            <div ref={closingStatementRef} className={`ClosingStatement ${closingStatementInView ? 'is-visible' : ''}`}>
                <div className="ClosingStatementHeader">
                    <p className="ClosingTitle">THANK YOU</p>
                    <p className="ClosingJapanese">どうもありがとう</p>
                </div>
                <p>{ isEnglish ? "We’ll see you on our special day!" : "Sampai jumpa di hari istimewa kami!" }</p>
            </div>

            <div ref={creditsRef} className={`Credits ${creditsInView ? 'is-visible' : ''}`}>
                <p>Crafted by yours truly. © 2024 Edho & Areta</p>
            </div>
        </div>
    )
}

export default Closing;