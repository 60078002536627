import './CircleButton.css';
import { useRipple } from "@chaocore/ripple";
import React from 'react'

function CircleButton({ text, clickHandler, disabled, horizontalPadding, verticalPadding }) {
  const ripple = useRipple({color:"gray"});
  
  const divStyle = {
    paddingTop: verticalPadding,
    paddingBottom: verticalPadding,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding
  };

  return (
    <button 
      ref={ ripple }
      className="btn"
      onClick={ clickHandler }
      disabled={ disabled }
      style={ divStyle }
    >
      {text}
    </button>
  )
}

export default CircleButton