import './App.css';
import Cover from './cover/Cover.js';
import Background from './background/Background.js';
import GroomBride from './groom_bride/GroomBride.js';
import Illustration from './illustration/Illustration.js';
import Address from './address/Address.js';
import Reservation from './reservation/Reservation.js';
import Comments from './comments/Comments.js';
import Gift from './gift/Gift.js';
import Closing from './closing/Closing.js';
import PinterestGrid from './components/Masonry.js';
import AddOnImage from './AddOnImage/AddOnImage.js';
import AudioPlayer from './audio_player/AudioPlayer.js';
import ReactModal from 'react-modal';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect, useRef } from "react";
import { createClient } from "@supabase/supabase-js";
import CircleButton from './components/CircleButton.js';
import YouTube from 'react-youtube';
import play from './globalImage/play.svg';
import pause from './globalImage/pause.svg';
import one from './globalImage/portrait/1.png';
import two from './globalImage/portrait/2.png';
import three from './globalImage/portrait/3.png';
import four from './globalImage/portrait/4.png';
import five from './globalImage/portrait/5.png';
import six from './globalImage/portrait/6.png';
import seven from './globalImage/portrait/7.png';
import eight from './globalImage/portrait/8.png';
import nine from './globalImage/portrait/9.png';
import ten from './globalImage/portrait/10.png';
import eleven from './globalImage/portrait/11.png';
import one_landscape from './globalImage/landscape/1.png';
import two_landscape from './globalImage/landscape/2.png';
import three_landscape from './globalImage/landscape/3.png';
import four_landscape from './globalImage/landscape/4.png';
import five_landscape from './globalImage/landscape/5.png';
import six_landscape from './globalImage/landscape/6.png';
import seven_landscape from './globalImage/landscape/7.png';
import eight_landscape from './globalImage/landscape/8.png';
import nine_landscape from './globalImage/landscape/9.png';
import ten_landscape from './globalImage/landscape/10.png';
import eleven_landscape from './globalImage/landscape/11.png';

export const supabase = createClient("https://wwkhmzafandjrszdwcwe.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Ind3a2htemFmYW5kanJzemR3Y3dlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA1OTk3NzUsImV4cCI6MjAzNjE3NTc3NX0.XUMoOG5vifgCqsaRGkiGJMdI_j3A26BqmG4qdpeSNqk");
export var isEnglish = true

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

// Custom hook to handle media queries
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const handleChange = (event) => setMatches(event.matches);

    mediaQueryList.addEventListener('change', handleChange);
    return () => mediaQueryList.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
}

function App() {
  const audioRef = useRef(null);
  const openerRef = useRef(null);
  const wrapperRef = useRef(null);
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const { ref: photoGridRef, inView: photoGridInView } = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0, // Trigger when 0% of the component is visible
  });

  const [divHeight, setDivHeight] = useState(true);
  const [show, setShow] = useState(true);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [commentLists, setCommentLists] = useState([]);
  const [audioImageState, setAudioImageState] = useState(pause);
  const [hideButtonOpenInv, setHideButtonOpenInv] = useState(false);
  const [idParam, setIdParam] = useState(null);
  const [userData, setUserData] = useState({
    fullname: "",
    attendance_confirmation: "",
    number_of_guests: 0,
    type: 1,
    wishes: "",
    is_single: false
});
  
  /// Scroll to top page when refresh from browser
  window.onbeforeunload = function () {
    setShow(true);
    window.scrollTo(0, 0);
  }
  
  document.body.style.backgroundColor = "#F9F4E9";

  const openInvitationHandler = e => {
    // Get the height of the #opener element
    const openerHeight = openerRef.current.offsetHeight;
    const viewportHeight = window.innerHeight
    const finalheight = (viewportHeight > openerHeight ? openerHeight : viewportHeight)
    wrapperRef.current.style.setProperty('--target-height', `-${finalheight}px`);

    var d = document.getElementById('Wrapper');
    d.className = 'enabled';

    setShow(false);

    setTimeout(function(){
      // Set the margin-top of the #Wrapper element
      setDivHeight(-openerHeight);
      setShowPlayer(true);
      setHideButtonOpenInv(true);
      audioRef.current.play();
      window.scrollTo(0, 0);
    }, 1500);
  };

  const handleUpdateData = (updatedData) => {
    updateReservation({updatedData: updatedData, idParam: idParam})
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  }

  const getSegmentBeforeLast = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/').filter(segment => segment.length > 0);
    
    // Ensure there is at least one segment before the last one
    if (segments.length < 2) {
      return null; // or any default value you prefer
    }
  
    return segments[segments.length - 2];
  };

  const getIdParam = () => {
    // Get the full pathname
    const urlParam = window.location.pathname;
    // Split the pathname into segments
    const segments = urlParam.split('/');
    // Get the last segment
    const lastSegment = segments[segments.length - 1];
    // Handle the case where the pathname ends with a trailing slash
    const result = lastSegment === '' ? segments[segments.length - 2] : lastSegment;

    return new URLSearchParams(result).toString().slice(0, -1);
  };

  useEffect(() => {
    const result = getSegmentBeforeLast()

    if (result == "id") {
      isEnglish = false
    }else{
      isEnglish = true
    }

    const idParam = getIdParam()

    setIdParam(idParam);
    getUserData(idParam);

    getCommentsLists();
  }, []);

  async function getUserData(idParam) {
    const networkUserData = await supabase
        .from("guests")
        .select("fullname, attendance_confirmation, number_of_guests, type, wishes, is_single")
        .eq("guestId", idParam)
        .single();

    if (networkUserData.data != null) {
        setUserData(networkUserData.data);
    }
}
  
  async function updateReservation({ updatedData, idParam }) {
    const { error } = await supabase
        .from('guests')
        .update(updatedData)
        .eq("guestId", idParam)
        .single()

    if (error) {
        alert(error.message)
    } else {
        getCommentsLists();
        setShowModal(true);
    }
  }

  async function getCommentsLists() {
    const userCommentLists = await supabase
        .from("guests")
        .select("fullname, wishes")
        .order("modifiedAt", { ascending: false }); // Sort by 'modifiedAt' column in descending order

    const filteredItems = userCommentLists.data.filter(item => item.wishes);
    setCommentLists(filteredItems)
  }

  const imageUrlsPortrait = [
    one, two, three, four, five, six, seven, eight, nine, ten, eleven
  ];

  const imageUrlsLandscape = [
    one_landscape, two_landscape, three_landscape, four_landscape, five_landscape, six_landscape, seven_landscape, eight_landscape, nine_landscape, ten_landscape, eleven_landscape
  ]

  const modalStyles = {
    content: {
      width: isPortrait ? '65%' : '40%',
      height: '150px',
      margin: 'auto',
      top: '25%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: '#F6F6F5',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      opacity: 1, // Ensures modal itself is fully opaque
    },
    overlay: {
      backgroundColor: 'rgba(98, 96, 97, 0.7)', // Gray background with 0.7 opacity
    },
  };

  const opts = {
    height: '100%', // Full height based on aspect ratio
    width: '100%',
    playerVars: {
      showinfo: 0,
      autoplay: 0, // Set to 1 for autoplay
    },
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) { // 1 indicates the video is playing
      if (audioRef.current) {
        audioRef.current.pause();
        setAudioImageState(play)
      }
    }else {
      if(audioRef.current) {
        audioRef.current.play()
        setAudioImageState(pause)
      }
    }
  };

  const handlePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setAudioImageState(pause);
    } else {
      audioRef.current.pause();
      setAudioImageState(play);
    }
  };
  
  const handleEnded = () => {
    // When the audio ends, loop it
    audioRef.current.currentTime = 0; // Reset currentTime
    audioRef.current.play();
    setAudioImageState(pause);
  };

  return (
    <div className='App'>
      <div id="Wrapper"
        ref={wrapperRef}
        style={{
          marginTop: divHeight,
        }}
      >
        <div id="opener" ref={openerRef}>
          <Cover openInvitationHandler={ openInvitationHandler } hideButtonOpenInv={ hideButtonOpenInv } />
        </div>
        { !show && (
          <div id="content">
            <div className={`AudioPlayer ${showPlayer ? 'is-visible' : ''}`}>
              <AudioPlayer 
                audioRef={ audioRef } 
                handlePlay={ handlePlay }
                handleEnded={ handleEnded }
                parentImageState={ audioImageState } 
              />
            </div>
            <div className="Background">
              <Background />
            </div>
            <div className="GroomBride">
              <GroomBride />
            </div>
            <div className="Illustration">
              <Illustration />
            </div>
            <div className="Address">
              <Address isHolyMatrimonyOnly={ userData.type == 2 }/>
            </div>
            <div className="YoutubeVideo">
              <YouTube
                videoId="dGanDarsNbA" // Replace with your YouTube video ID
                opts={opts}
                onStateChange={onPlayerStateChange}
                />
            </div>
            <div className="SubSectionAfterYoutube">
              <AddOnImage className="AddOnImage"/>
            </div>
            <div className="Reservation">
              <Reservation handleSubmitReservation={ handleUpdateData } userData={ userData } idParam={ idParam }/>
            </div>
            <div className="Comments">
              <Comments commentLists={ commentLists }/>
            </div>
            <div className="Gift">
              <Gift />
            </div>
            <div ref={photoGridRef} className={`PhotoGrid ${photoGridInView ? 'is-visible' : ''}`}>
              <div className="PinterestGrid">
                <PinterestGrid imageUrls={ isPortrait ? imageUrlsPortrait : imageUrlsLandscape } />
              </div>
            </div>
            <div className="Closing">
              <Closing />
            </div>
          </div>
        )}
      </div>
      <ReactModal 
           isOpen={ showModal }
           contentLabel="Minimal Modal Example"
           style={ modalStyles }
        >
          <div className="modalContent">
            <p className="headerModal">{ isEnglish ? "Thank you!" : "Terima kasih!" }</p>
            <p className="modalSubtitle">{ isEnglish ? "Your RSVP has been submitted." : "RSVP Saudara/i telah dikirimkan." }</p>
            <div>
              <CircleButton 
                  text={ isEnglish ? "Okay" : "Oke" } 
                  clickHandler={ handleCloseModal }
                  horizontalPadding={ "12px" }
                  verticalPadding={ "8px" }
              />
            </div>
          </div>
      </ReactModal>
    </div>
  );
}

export default App;